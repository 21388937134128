exports.components = {
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-coming-soon-tsx": () => import("./../../../src/pages/coming-soon.tsx" /* webpackChunkName: "component---src-pages-coming-soon-tsx" */),
  "component---src-pages-funding-charity-model-tsx": () => import("./../../../src/pages/funding/charity-model.tsx" /* webpackChunkName: "component---src-pages-funding-charity-model-tsx" */),
  "component---src-pages-funding-fundraising-tsx": () => import("./../../../src/pages/funding/fundraising.tsx" /* webpackChunkName: "component---src-pages-funding-fundraising-tsx" */),
  "component---src-pages-funding-panda-pals-tsx": () => import("./../../../src/pages/funding/panda-pals.tsx" /* webpackChunkName: "component---src-pages-funding-panda-pals-tsx" */),
  "component---src-pages-galleries-tsx": () => import("./../../../src/pages/galleries.tsx" /* webpackChunkName: "component---src-pages-galleries-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-pandas-fees-tsx": () => import("./../../../src/pages/new-pandas/fees.tsx" /* webpackChunkName: "component---src-pages-new-pandas-fees-tsx" */),
  "component---src-pages-new-pandas-policies-tsx": () => import("./../../../src/pages/new-pandas/policies.tsx" /* webpackChunkName: "component---src-pages-new-pandas-policies-tsx" */),
  "component---src-pages-new-pandas-settling-in-tsx": () => import("./../../../src/pages/new-pandas/settling-in.tsx" /* webpackChunkName: "component---src-pages-new-pandas-settling-in-tsx" */),
  "component---src-pages-our-approach-aims-tsx": () => import("./../../../src/pages/our-approach/aims.tsx" /* webpackChunkName: "component---src-pages-our-approach-aims-tsx" */),
  "component---src-pages-our-approach-green-panda-tsx": () => import("./../../../src/pages/our-approach/green-panda.tsx" /* webpackChunkName: "component---src-pages-our-approach-green-panda-tsx" */),
  "component---src-pages-our-team-committee-tsx": () => import("./../../../src/pages/our-team/committee.tsx" /* webpackChunkName: "component---src-pages-our-team-committee-tsx" */),
  "component---src-pages-our-team-staff-tsx": () => import("./../../../src/pages/our-team/staff.tsx" /* webpackChunkName: "component---src-pages-our-team-staff-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-sessions-pre-school-tsx": () => import("./../../../src/pages/sessions/pre-school.tsx" /* webpackChunkName: "component---src-pages-sessions-pre-school-tsx" */),
  "component---src-pages-sessions-stay-and-play-tsx": () => import("./../../../src/pages/sessions/stay-and-play.tsx" /* webpackChunkName: "component---src-pages-sessions-stay-and-play-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */)
}

